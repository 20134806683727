import {
  FormatCurrency,
  FormatCurrencyNoDecimals,
  FormatCurrencyToNearestUpperMultipleToNoDecimals,
} from '@src/services/Formatter'
import { Constants, EDefaultPaymentPlanIdEnum, EPaymentFrequency, EWorksheetStatus } from '@src/types/constants'
import { FormEventHandler, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useGetPromotionalCreditAppPaymentAmount } from '@src/api/compute-api'
import { EditWorksheetDto, EOriginSystemId, FilteredCreditApplication, useUpdateWorksheet } from '@src/api/credit-api'
import { AsyncActionButton, CancelAppButton, Loader, ManageCoappButton } from '@src/components'
import { useTermAmountOptions } from '@src/data/credit-application-hooks'
import { getLoanAmount, hasFlexPlan } from '@src/data/credit-application-selectors'
import { reportErrorToConsole } from '@src/services/error-logger'
import { ETrackedEvents, useTrackStepReachedEvent } from '@src/services/trackingEvents'
import { Link, useParams } from 'react-router-dom'
import useTermPaymentsTable from './hooks'
import MonthlyPaymentEstimationNotice from '../components/MonthlyPaymentEstimationNotice'

type Props = {
  creditApp: FilteredCreditApplication
}
const InitialApprobationForm = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const params = useParams()
  const id = params.id as string
  const [monthlyPaymentPerMonths, isLoadingTermTable] = useTermPaymentsTable(creditApp)
  const [promotionalAmounts, isLoadingPromoAmounts] = useGetPromotionalCreditAppPaymentAmount(
    getLoanAmount(creditApp),
    id,
    creditApp.merchantPaymentPlanId,
  )
  const [submitWorksheetUpdate, isSubmitting] = useUpdateWorksheet()
  const termOptions = useTermAmountOptions(monthlyPaymentPerMonths)

  useTrackStepReachedEvent(creditApp, ETrackedEvents.QualifSuccess)

  const hasAdminFee =
    creditApp.merchantPaymentPlan?.borrowerFeeRate && creditApp.merchantPaymentPlan?.borrowerFeeRate > 0
  const exponent = hasAdminFee ? '1' : ''
  const hasFlex = hasFlexPlan(creditApp)
  const promotionText = hasFlex ? 'decision.hardhitDecisionApprovedFlex' : 'decision.hardhitDecisionApprovedFlash'
  const hasCoapplicant = creditApp.coapplicant != null
  const canAddCoapp =
    creditApp.consentHardHit === true &&
    creditApp.requestedLoanAmount > creditApp.finalDecision.maxAmountFinanced &&
    creditApp.originSystemId !== EOriginSystemId.MerchantDashboard

  const submitTerm: FormEventHandler = (e) => {
    e.preventDefault()
    if (creditApp) {
      const term =
        creditApp.merchantPaymentPlan !== null
          ? creditApp.merchantPaymentPlan.loanTerm
          : parseInt(termOptions[termOptions.length - 1].id, 10)

      const dto: EditWorksheetDto = {
        term,
        amountRequested: getLoanAmount(creditApp),
        creditApplicationId: creditApp.id,
        paymentFrequency: EPaymentFrequency.Monthly,
        deliveryOn: null,
        firstPaymentOn: null,
        includeInsurance: false,
        status: EWorksheetStatus.Draft,
        paymentPlanId: creditApp.merchantPaymentPlanId || EDefaultPaymentPlanIdEnum.RegularDailyInterests,
        create: creditApp.worksheet === null,
        versionTag: creditApp.worksheet?.versionTag ?? null,
      }
      submitWorksheetUpdate(dto).catch(reportErrorToConsole)
    }
  }

  const getTitle = useMemo<string>(() => {
    const hasSkipIncomeVerification =
      creditApp.applicant.hasSkippedIncomeValidation &&
      (creditApp.coapplicant != null ? creditApp.coapplicant.hasSkippedIncomeValidation : true)
    return hasSkipIncomeVerification
      ? t('decision.hardhitDecisionApprovedTitle')
      : t('decision.hardhitDecisionConditionallyApprovedTitle')
  }, [creditApp, t])

  if (isLoadingPromoAmounts || isLoadingTermTable || !creditApp)
    return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />

  const requestedAmountReached = creditApp.requestedLoanAmount <= creditApp.finalDecision.maxAmountFinanced

  return (
    <main className="general-message" style={{ display: 'block' }}>
      <form onSubmit={submitTerm}>
        <div id="loan-form-container">
          <h3>
            {requestedAmountReached && (
              <>
                <i className="fa-solid fa-party-horn" /> {t('common.congratulation')}{' '}
              </>
            )}
            {getTitle}
          </h3>
          <div className="paragraph">
            {creditApp.merchantPaymentPlan !== null && promotionalAmounts?.paymentForFrequency && (
              <p>
                <Trans
                  i18nKey={promotionText}
                  values={{
                    maximumAmount: FormatCurrencyNoDecimals(creditApp.finalDecision.maxAmountFinanced),
                    reducedInterestRate: creditApp.merchantPaymentPlan.reducedInterestRate,
                    reducedRateDurationInMonths: creditApp.merchantPaymentPlan.reducedRateDurationInMonths,
                  }}
                />
                !{hasFlex && <sup style={{ fontSize: '1rem', color: 'red' }}>1</sup>}
                &nbsp;
                <Trans
                  i18nKey="decision.estimatedPayment"
                  values={{
                    monthlyPayment: FormatCurrencyToNearestUpperMultipleToNoDecimals(
                      promotionalAmounts.paymentForFrequency,
                      5,
                    ),
                  }}
                />
                .{hasFlex && hasAdminFee && <sup style={{ fontSize: '1rem', color: 'red' }}>2</sup>}
                {!hasFlex && exponent && hasAdminFee && <sup style={{ fontSize: '1rem', color: 'red' }}>1</sup>}
              </p>
            )}

            {creditApp.merchantPaymentPlan === null &&
              monthlyPaymentPerMonths &&
              monthlyPaymentPerMonths.length > 0 && (
                <>
                  <p>
                    <Trans
                      i18nKey="decision.hardhitDecisionApproved"
                      values={{
                        maximumAmount: FormatCurrencyNoDecimals(creditApp.finalDecision.maxAmountFinanced),
                        minPaymentAmount: FormatCurrency(
                          monthlyPaymentPerMonths[monthlyPaymentPerMonths.length - 1].amountWithoutInsurance,
                        ),
                      }}
                    />
                  </p>
                  {canAddCoapp && (
                    <div style={{ marginTop: '32px', marginBottom: '32px' }}>
                      <div className="strong">{t('prequalification.addCoapplicant.title')}</div>
                      <div className="btn-group">
                        <ManageCoappButton creditAppId={creditApp.id} useChangeLabel={hasCoapplicant} />
                      </div>
                    </div>
                  )}
                  <p style={{ textAlign: 'center' }}>
                    <br />
                    <Trans
                      i18nKey="loan.youHaveQuestions"
                      values={{
                        phoneNumber: '1-888-689-9876',
                      }}
                      components={{ a: <Link to="tel:18886899876" style={{ textWrap: 'nowrap' }} /> }}
                    />
                  </p>
                </>
              )}
          </div>
          <div className="btn-group mobile-reversed space-between" style={{ marginTop: '32px' }}>
            <CancelAppButton creditAppId={id} />
            <AsyncActionButton type="submit" className="btn btn-blue" isPending={isSubmitting} primary>
              <span>
                {requestedAmountReached
                  ? t('common.imInterested')
                  : t('common.continueWith', { amount: FormatCurrencyNoDecimals(creditApp.requestedLoanAmount) })}
              </span>
              <i className="fa-regular fa-arrow-right" />
            </AsyncActionButton>
          </div>

          <div style={{ marginTop: '4rem' }}>
            {creditApp.merchantPaymentPlan !== null && hasFlex && (
              <div style={{ fontSize: '1.5rem' }}>
                <sup style={{ fontSize: '1rem', color: 'red' }}>1</sup>
                &nbsp;
                <Trans
                  dangerouslySetInnerHTML
                  i18nKey="decision.flexRemainder"
                  values={{
                    months:
                      creditApp.merchantPaymentPlan.loanTerm -
                      creditApp.merchantPaymentPlan.reducedRateDurationInMonths,
                    interestRate: creditApp.merchantPaymentPlan.interestRate,
                  }}
                />
              </div>
            )}

            {(!creditApp.merchantPaymentPlan === null || exponent !== '') && hasAdminFee && (
              <div style={{ fontSize: '1.5rem' }}>
                <sup style={{ fontSize: '1rem', color: 'red' }}>{hasFlex ? '2' : '1'}</sup>
                &nbsp;
                {t('decision.includedFee', {
                  adminFeeRate:
                    creditApp.merchantPaymentPlan !== null
                      ? creditApp.merchantPaymentPlan.borrowerFeeRate
                      : Math.round(Constants.FinanceFeeRateForRegular * 100),
                })}
              </div>
            )}
            {creditApp.merchantPaymentPlan === null && !hasAdminFee && (
              <MonthlyPaymentEstimationNotice creditApp={creditApp} />
            )}
          </div>
        </div>
      </form>
    </main>
  )
}

export default InitialApprobationForm
