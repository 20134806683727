import React, { FormEvent, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AcceptTermsCheckbox } from '@src/components'
import { EApplicationStep } from '@src/types'
import { hasMissingDocuments } from '@src/data/credit-application-selectors'
import UploadFilesTable from '@src/components/UploadFilesTable'
import { FilteredCreditApplication } from '@src/api/credit-api'
import UnderReviewTile from '../../components/UnderReviewTile'

type Props = {
  creditApp: FilteredCreditApplication
}
const FailedToObtainCreditReportForm = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const [showUploadFile, setShowUpload] = useState(false)
  const hasMissingDocs = hasMissingDocuments(creditApp, EApplicationStep.Credit)

  const toggleUploadFile = (e: FormEvent) => {
    e.preventDefault()
    setShowUpload(true)
  }

  return (
    <main className="general-message" style={{ paddingTop: 0 }}>
      {!showUploadFile && hasMissingDocs && (
        <form onSubmit={toggleUploadFile}>
          <div style={{ textAlign: 'center' }}>
            <h4 className="subtitle">{t('common.financeApplication')}</h4>
          </div>
          <h1>{t('identity.cannotConfirm')}</h1>
          <div className="paragraph">
            <p>
              <Trans i18nKey="identity.hereToHelp" />
            </p>
            <p>{t('identity.notifyEmail')}</p>
            <p>{t('identity.consent')}</p>
            <AcceptTermsCheckbox
              id="acceptConditions"
              termId="identity.byClicking"
              msgOnInvalid="common.acceptConditions"
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <button type="submit" className="btn btn-blue">
              <span>{t('common.submit')}</span>
              <i className="fa-regular fa-arrow-right" />
            </button>
          </div>
        </form>
      )}
      {showUploadFile && hasMissingDocs && <UploadFilesTable creditApp={creditApp} />}
      {!hasMissingDocs && <UnderReviewTile />}
    </main>
  )
}

export default FailedToObtainCreditReportForm
