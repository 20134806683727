import { EditWorksheetDto, FilteredCreditApplication, FilteredWorksheet, useUpdateWorksheet } from '@src/api/credit-api'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Loader } from '@src/components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useGetHolidaysForYear } from '@src/api/holiday-api'
import { useGetAvailableCredit } from '@src/api/available-credit'
import { compareAsc } from 'date-fns'
import { formatDate } from '@src/services/Formatter'
import { EDefaultPaymentPlanIdEnum, EPaymentFrequency, EProvince, EWorksheetStatus } from '@src/types'
import Stepper from '@src/components/Stepper/Stepper'
import StepperStep from '@src/components/Stepper/StepperStep'
import { Merchant, useMerchantPaymentPlanById } from '@src/api/merchants-api'
import { AxiosError, HttpStatusCode } from 'axios'
import { WorksheetForm, useWorksheetSchema } from './schema-hooks'
import { cantSelectDateForActivation, getClosestDeliveryOn } from './selectors'
import { DEFAULT_STEP, EWorksheetStep, useWorksheetStepIsValid } from './hooks'
import FirstPaymentStep from './components/FirstPaymentStep'
import TermStep from './components/TermStep'
import InsuranceStep from './components/InsuranceStep'
import SummaryStep from './components/SummaryStep'
import FinalAmountStep from './components/FinalAmountStep'

interface Props {
  step?: EWorksheetStep
  setStep: (step: EWorksheetStep) => void
  creditApp: FilteredCreditApplication
  merchant: Merchant
  worksheet: FilteredWorksheet
}

const WorksheetStepper = ({
  merchant,
  creditApp,
  step = EWorksheetStep.AmountRequested,
  setStep,
  worksheet,
}: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [error, setError] = useState<string>('')
  const [codePromo, setCodePromo] = useState<string | null>(null)
  const [submitWorksheetUpdate, isSubmitting] = useUpdateWorksheet()
  const [currentDate] = useState(new Date())
  const [listHolidays, isLoadingHolidays] = useGetHolidaysForYear(currentDate.getFullYear())
  const soonestDeliveryOn = useMemo(() => getClosestDeliveryOn(currentDate, listHolidays), [listHolidays, currentDate])
  const [availableCredit, isLoadingAvailableCredit] = useGetAvailableCredit(creditApp.id, true)

  const handleSetStep = useCallback((s: string) => setStep(s as EWorksheetStep), [setStep])

  const canHaveProtection = useMemo<boolean>(() => {
    return (
      creditApp.applicant.province !== EProvince.quebec &&
      creditApp.applicant.province !== EProvince.saskatchewan &&
      creditApp.applicant.age <= 65
    )
  }, [creditApp.applicant.age, creditApp.applicant.province])

  const steps = useMemo<EWorksheetStep[]>(() => {
    const stepList = []
    stepList.push(EWorksheetStep.AmountRequested, EWorksheetStep.FirstPayment, EWorksheetStep.PayOverTime)
    if (canHaveProtection) stepList.push(EWorksheetStep.ProtectionPlan)
    stepList.push(EWorksheetStep.Summary)
    return stepList
  }, [canHaveProtection])

  const schema = useWorksheetSchema(
    availableCredit?.availableAmount ?? creditApp.finalDecision.maxAmountFinanced,
    worksheet.amountRequested,
    worksheet.term,
  )

  const form = useForm<WorksheetForm>({
    disabled: isSubmitting,
    mode: 'onChange',
    defaultValues: {
      firstPaymentOn: formatDate(worksheet.firstPaymentOn) || undefined,
      amountRequested: worksheet.amountRequested,
      term: creditApp.merchantPaymentPlan?.loanTerm || worksheet.term,
      includeInsurance: worksheet.includeInsurance,
    },
    resolver: yupResolver(schema),
  })

  // Anti skip step
  useWorksheetStepIsValid(step, steps, form.trigger)
  // Additionnal step validation
  useEffect(() => {
    if (!canHaveProtection && step === EWorksheetStep.ProtectionPlan)
      navigate(`../worksheet/${DEFAULT_STEP}`, { replace: true })
  }, [canHaveProtection, navigate, step])

  const [merchantPaymentPlan, isLoadingMerchantPaymentPlan] = useMerchantPaymentPlanById(
    merchant.id,
    form.watch('merchantPaymentPlanId'),
  )

  useEffect(() => {
    if (merchantPaymentPlan?.loanTerm) {
      form.setValue('term', merchantPaymentPlan.loanTerm, { shouldDirty: true, shouldValidate: true })
    }
  }, [form, merchantPaymentPlan?.loanTerm])

  const onFormSubmitted = useCallback(
    (data: WorksheetForm) => {
      if (!soonestDeliveryOn) {
        setError('Delivery date is not available.')
        return
      }
      const nowUTC = new Date(`${soonestDeliveryOn.toLocaleDateString()}T00:00:00`)
      const expiry = new Date(creditApp.expiresOn)
      const receivedOnPlus90Days = new Date(creditApp.hardHitReportReceivedOn)
      receivedOnPlus90Days.setDate(receivedOnPlus90Days.getDate() + 90)
      if (cantSelectDateForActivation(soonestDeliveryOn, listHolidays)) {
        setError('Delivery on cant be a holiday')
      } else if (data.amountRequested > (availableCredit?.availableAmount ?? 0)) {
        setError('worksheetCommon.amountRequestedHigherThanAvailableCredit')
      } else if (compareAsc(soonestDeliveryOn, receivedOnPlus90Days) === 1)
        setError('worksheetCommon.activationDateReport90Days')
      else if (compareAsc(nowUTC, expiry) === 1) setError('worksheetCommon.deliveryOnEarlierExpiration')
      else {
        const ws: EditWorksheetDto = {
          ...worksheet,
          amountRequested: Number(data.amountRequested),
          term: data.term,
          firstPaymentOn: data.firstPaymentOn,
          includeInsurance: data.includeInsurance,
          paymentFrequency: EPaymentFrequency.Monthly,
          deliveryOn: formatDate(soonestDeliveryOn),
          status: EWorksheetStatus.Active,
          creditApplicationId: creditApp.id,
          paymentPlanId:
            data.merchantPaymentPlanId ||
            creditApp.merchantPaymentPlanId ||
            EDefaultPaymentPlanIdEnum.RegularDailyInterests,
          create: false,
          versionTag: worksheet.versionTag,
        }
        submitWorksheetUpdate(ws)
          .then(() => {
            navigate(`/creditApplication/${creditApp.id}/contract`)
          })
          .catch((err: AxiosError) => {
            if (err.response?.status === HttpStatusCode.Conflict) setError(t('common.conflict'))
            else setError(err.message)
          })
      }
    },
    [
      availableCredit?.availableAmount,
      creditApp.expiresOn,
      creditApp.hardHitReportReceivedOn,
      creditApp.id,
      creditApp.merchantPaymentPlanId,
      listHolidays,
      navigate,
      soonestDeliveryOn,
      submitWorksheetUpdate,
      t,
      worksheet,
    ],
  )

  if (isLoadingHolidays || isLoadingAvailableCredit)
    return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />

  return (
    <main className="general-message">
      {error && <Alert type="error" message={error} />}
      <div className="form-section" style={{ width: '100%' }}>
        <FormProvider {...form}>
          <Stepper current={step} setCurrentStep={handleSetStep} isSubmitting={isSubmitting}>
            <StepperStep
              step={EWorksheetStep.AmountRequested}
              icon="hand-holding-dollar"
              label={t('worksheet.finalAmount.step')}
              render={({ setState }) => (
                <FinalAmountStep
                  creditApp={creditApp}
                  merchant={merchant}
                  setStepState={setState}
                  setCodePromo={setCodePromo}
                  codePromo={codePromo}
                  maxAmountFinanced={creditApp.finalDecision.maxAmountFinanced}
                />
              )}
            />
            <StepperStep
              step={EWorksheetStep.FirstPayment}
              icon="calendar-day"
              label={t('worksheet.firstPayment.step')}
              loading={isLoadingMerchantPaymentPlan}
              hideControl
              render={({ setState, setNext }) => (
                <FirstPaymentStep
                  setStepState={setState}
                  setNext={setNext!}
                  soonestDeliveryOn={soonestDeliveryOn}
                  listHolidays={listHolidays}
                />
              )}
            />
            <StepperStep
              step={EWorksheetStep.PayOverTime}
              icon="percent"
              label={t('worksheet.term.step')}
              disabled={Boolean(creditApp.merchantPaymentPlan?.loanTerm) || Boolean(merchantPaymentPlan?.loanTerm)}
              hideControl
              render={({ setState, setNext }) => (
                <TermStep
                  setStepState={setState}
                  setNext={setNext!}
                  creditApp={creditApp}
                  soonestDeliveryOn={soonestDeliveryOn}
                />
              )}
            />
            {canHaveProtection && (
              <StepperStep
                step={EWorksheetStep.ProtectionPlan}
                icon="shield"
                label={t('worksheet.protection.step')}
                hideControl
                render={({ setState, setNext }) => (
                  <InsuranceStep
                    setStepState={setState}
                    setNext={setNext!}
                    creditApp={creditApp}
                    soonestDeliveryOn={soonestDeliveryOn}
                  />
                )}
              />
            )}
            <StepperStep
              step={EWorksheetStep.Summary}
              icon="memo-circle-check"
              label={t('worksheet.summary.step')}
              hideControl
              render={({ setState }) => (
                <SummaryStep
                  setStepState={setState}
                  setCurrentStep={handleSetStep}
                  creditApp={creditApp}
                  soonestDeliveryOn={soonestDeliveryOn}
                  onSubmit={form.handleSubmit(onFormSubmitted)}
                  merchantPaymentPlan={merchantPaymentPlan}
                  isSubmitting={isSubmitting}
                />
              )}
            />
          </Stepper>
        </FormProvider>
      </div>
    </main>
  )
}

export default WorksheetStepper
