import { useTranslation } from 'react-i18next'
import { FormatCurrency } from '@src/services/Formatter'
import { useFormContext } from 'react-hook-form'
import { WorksheetForm } from '@src/containers/Worsheet/schema-hooks'
import { format } from 'date-fns'
import { useEffect, useMemo } from 'react'
import useTermPaymentsTable from '@src/containers/ViewCreditApplication/InitialApprobationPage/hooks'
import { FilteredCreditApplication, MerchantPaymentPlan } from '@src/api/credit-api'
import { StepStateUpdater } from '@src/components/Stepper/StepperStep'
import { EWorksheetStep } from '../hooks'

type Props = {
  creditApp: FilteredCreditApplication
  soonestDeliveryOn: Date
  setStepState: StepStateUpdater
  onSubmit: () => void
  setCurrentStep: (step: EWorksheetStep) => void
  merchantPaymentPlan: MerchantPaymentPlan | null
  isSubmitting: boolean
}

const SummaryStep = ({
  creditApp,
  soonestDeliveryOn,
  setStepState,
  onSubmit,
  setCurrentStep,
  merchantPaymentPlan,
  isSubmitting,
}: Props) => {
  const { getValues } = useFormContext<WorksheetForm>()

  const { amountRequested, term, firstPaymentOn, includeInsurance, merchantPaymentPlanId } = getValues()
  const { t } = useTranslation()

  const [monthlyPaymentPerMonths, isLoadingTerms] = useTermPaymentsTable(creditApp, {
    firstPaymentOn,
    activationDate: soonestDeliveryOn,
    requestedAmount: Number(amountRequested),
    paymentPlanId: merchantPaymentPlanId || creditApp.merchantPaymentPlanId,
    includeInsurance,
  })

  const selectedTerm = useMemo(
    () => monthlyPaymentPerMonths.find((mp) => mp.term === term),
    [monthlyPaymentPerMonths, term],
  )

  useEffect(() => setStepState({ loading: isLoadingTerms }), [isLoadingTerms, setStepState])

  if (isLoadingTerms) return <div className="loader" />

  const hasPromotion = Boolean(creditApp.merchantPaymentPlan) || Boolean(merchantPaymentPlan)

  return (
    <div>
      <h3>{t('worksheet.summary.title')}</h3>
      <ul className="list">
        <li className="list-item">
          <h6 className="fi-6">{t('worksheet.summary.amount')}</h6>
          <h5 className="fi-4 strong text-end">{FormatCurrency(amountRequested)}</h5>
          <div className="fi-2" style={{ display: 'flex', justifyContent: 'end' }}>
            <button
              className="btn-icon"
              onClick={() => setCurrentStep(EWorksheetStep.AmountRequested)}
              aria-label={t('common.modify')}
            >
              <i className="fa-light fa-pen" />
            </button>
          </div>
        </li>
        <li className="list-item">
          <h6 className="fi-6">{t('worksheet.summary.term')}</h6>
          <h5 className="fi-4 strong text-end">{term}</h5>
          <div className="fi-2" style={{ display: 'flex', justifyContent: 'end', minHeight: '3em' }}>
            {!hasPromotion && (
              <button
                className="btn-icon"
                onClick={() => setCurrentStep(EWorksheetStep.PayOverTime)}
                aria-label={t('common.modify')}
              >
                <i className="fa-light fa-pen" />
              </button>
            )}
          </div>
        </li>
        <li className="list-item">
          <div className="fi-6">
            <h6>{t('worksheet.summary.term_amount')}</h6>
            {includeInsurance && (
              <div className="caption">
                {t('worksheet.summary.protection', { insuranceAmount: FormatCurrency(selectedTerm?.insuranceDelta) })}
              </div>
            )}
          </div>
          <h5 className="fi-4 strong text-end">
            {FormatCurrency(
              includeInsurance ? selectedTerm?.amountWithInsurance : selectedTerm?.amountWithoutInsurance,
            )}
          </h5>
          <div className="fi-2" style={{ display: 'flex', justifyContent: 'end', minHeight: '3em' }}>
            {!hasPromotion && (
              <button
                className="btn-icon"
                onClick={() => setCurrentStep(EWorksheetStep.PayOverTime)}
                aria-label={t('common.modify')}
              >
                <i className="fa-light fa-pen" />
              </button>
            )}
          </div>
        </li>
        <li className="list-item">
          <h6 className="fi-6">{t('worksheet.summary.interest_rate')}</h6>
          <h5 className="fi-4 strong text-end">
            {t(
              'worksheet.summary.interest',
              hasPromotion
                ? {
                    value: merchantPaymentPlan?.reducedInterestRate,
                    count: merchantPaymentPlan?.reducedRateDurationInMonths,
                  }
                : {
                    value: creditApp.finalDecision.interestRate,
                    count: selectedTerm?.term,
                  },
            )}
          </h5>
          <div className="fi-2" style={{ minHeight: '3em' }} />
        </li>
        <li className="list-item">
          <h6 className="fi-6">{t('worksheet.summary.date')}</h6>
          <h5 className="fi-4 strong text-end">{format(firstPaymentOn, 'PPP')}</h5>
          <div className="fi-2" style={{ display: 'flex', justifyContent: 'end' }}>
            <button
              className="btn-icon"
              onClick={() => setCurrentStep(EWorksheetStep.FirstPayment)}
              aria-label={t('common.modify')}
            >
              <i className="fa-light fa-pen" />
            </button>
          </div>
        </li>
      </ul>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <button className="btn btn-blue" onClick={onSubmit} disabled={isLoadingTerms || isSubmitting}>
          {t('worksheet.submit')}
        </button>
      </div>
    </div>
  )
}

export default SummaryStep
