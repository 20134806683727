import { EOriginSystemId, FilteredCreditApplication } from '@src/api/credit-api'
import { useGetFeedback } from '@src/api/feedback-api'
import { ErrorPage, Loader } from '@src/components'
import { applicantFullName } from '@src/data/credit-application-selectors'
import { replaceWithNonBreakingHyphen } from '@src/services/utils'
import { EApplicantType, RequiredDocumentWithStatus } from '@src/types'
import { useTranslation } from 'react-i18next'
import { useMerchantById } from '@src/api/merchants-api'
import { getFeedbackStepStatus } from '../hooks'
import ContractCreditStep from './components/ContractCreditStep'
import CreditStep, { CreditStepStatus } from './components/CreditStep'
import DocumentsCreditStep from './components/DocumentsCreditStep'
import IncomeCreditStep from './components/IncomeCreditStep'
import WorksheetCreditStep from './components/WorkSheetCreditStep'

type Props = {
  creditApp: FilteredCreditApplication
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[]
}

const OverviewPage = ({ creditApp, skipBankAccountRequiredDocuments }: Props) => {
  const { t } = useTranslation()
  const [feedback, isLoadingFeedback] = useGetFeedback(creditApp.id)
  const [merchant, isLoadingMerchant] = useMerchantById(creditApp.merchantId)
  const feedbackStepStatus = getFeedbackStepStatus(creditApp, skipBankAccountRequiredDocuments, feedback)

  if (isLoadingFeedback && isLoadingMerchant)
    return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />
  if (merchant === null) return <ErrorPage />

  return (
    <div className="container container-centered">
      <div className="limited-width-container">
        <div className="credit-steps">
          <h3>{t('common.appNumber', { number: creditApp.referenceNumber })}</h3>
          <span style={{ textAlign: 'center' }}>
            {replaceWithNonBreakingHyphen(applicantFullName(creditApp.applicant))}
            {creditApp.coapplicant !== null && (
              <span> & {replaceWithNonBreakingHyphen(applicantFullName(creditApp.coapplicant))}</span>
            )}
          </span>
        </div>
        <div className="credit-steps" style={{ marginTop: '2rem' }}>
          {creditApp.originSystemId !== EOriginSystemId.MerchantDashboard && (
            <CreditStep
              status={CreditStepStatus.Completed}
              linkToDetail="credit-summary"
              noConnector
              title={t('overview.creditStep')}
              content={t('overview.creditStepActions')}
            />
          )}
          <IncomeCreditStep
            creditApp={creditApp}
            applicantType={EApplicantType.Applicant}
            skipBankAccountRequiredDocuments={skipBankAccountRequiredDocuments}
          />
          {creditApp.coapplicant && (
            <IncomeCreditStep
              creditApp={creditApp}
              applicantType={EApplicantType.CoApplicant}
              skipBankAccountRequiredDocuments={skipBankAccountRequiredDocuments}
            />
          )}

          <DocumentsCreditStep creditApp={creditApp} />

          {merchant.noPaymentPolicy && <WorksheetCreditStep creditApp={creditApp} />}
          {!merchant.noPaymentPolicy && (
            <ContractCreditStep
              creditApp={creditApp}
              skipBankAccountRequiredDocuments={skipBankAccountRequiredDocuments}
              applicantType={EApplicantType.Applicant}
            />
          )}

          {creditApp.coapplicant && (
            <ContractCreditStep
              creditApp={creditApp}
              skipBankAccountRequiredDocuments={skipBankAccountRequiredDocuments}
              applicantType={EApplicantType.CoApplicant}
            />
          )}
          <CreditStep
            status={feedbackStepStatus}
            linkToDetail="feed-back"
            title={t('overview.rateExperienceStep')}
            content={t('overview.rateExperienceContent')}
            hideLinkToDetail={feedbackStepStatus !== CreditStepStatus.RequiresAttention}
          />
        </div>
      </div>
    </div>
  )
}

export default OverviewPage
